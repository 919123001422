@import "./../../app/theme.less";

.contract-user {
  &__ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    border-bottom: 1px dashed #3d3d3d;
    margin-bottom: 10px !important;
    padding-bottom: 10px !important;
    // justify-content: space-between;
    flex-wrap: wrap;
    li {
      margin-left: 8px;
      margin-bottom: 8px;
      width: 250px;
      & > span {
        font-weight: bold;
        margin-left: 4px;
      }
    }
  }
  .contract__text {
    text-align: justify;
    overflow-y: auto;
    height: 50vh;
    padding: 8px;
    p {
      font-family: IRANYekan !important;
    }
    span {
      font-family: IRANYekan !important;
    }
  }
  &__tools {
    display: flex;
    flex-direction: row-reverse;
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      // background-color: red;
      li {
        margin-right: 10px;
        font-size: 16px;
      }
    }
  }
  .contract__file-upload {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #eee;
  }
}

.user-role {
  .role_title {
    font-size: @font-size-lg;
    padding-top: 10px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    .icon {
      margin-left: 10px;
      color: @primary-color;
      font-size: @font-size-lg + 8px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    .role_item {
      flex-grow: 1;
      // min-width: 300px;
      max-width: 400px;
      width: 30%;
      display: flex;
      align-items: center;
      padding: 12px 10px;
      background: #fff;
      border: 1px solid #e5e9ee;
      box-shadow: 0px 0px 13px rgba(63, 77, 105, 0.04);
      border-radius: 6px;
    }
  }
  &__privileges {
    // background-color: #f7f7f7;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
  }
}

.service-setting {
  .code-area {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    direction: ltr;
    border: 1px solid #eee;
  }
}

.rocketchat-container {
  font-family: "IRANYekan" !important;
  #rocketchat-iframe {
    font-family: "IRANYekan" !important;
    html,
    p {
      font-family: "IRANYekan" !important;
    }
  }
}

.popup__modal {
  width: auto !important;
  max-width: 780px !important;
  text-align: center;
  .ant-modal-content {
    padding: 0px;
    border-radius: 20px !important;
    @media (max-width: @screen-sm) {
      margin: 12px !important;
    }
  }
  .ant-modal-body {
    padding: 0px !important;
  }
  &__content {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image_preview {
    width: 100%;
  }
  .popup__text {
    margin-top: 20px !important;
  }
}

.product_ect {
  .old-percent {
    text-decoration: line-through;
  }
}

.help_comm_modal {
  direction: rtl;
  .ant-modal-header {
    padding-right: 42px;
  }
  &__content {
    img {
      width: 100%;
    }
    text-align: justify;
  }
}

.formula-generator {
  &__wrapper {
    border: 1px solid #ececec;
    padding: 8px;
    border-radius: 6px;
  }
  fieldset {
    background-color: #f9f9f9;
    padding: 10px;
    direction: ltr;
    legend {
      direction: rtl;
    }
    & > p {
      direction: rtl;
    }
    form {
      direction: ltr;
    }
  }
  .v-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.d-icon {
  font-size: 1.4em;
  margin-top: 8px;
  color: #89929e;
  margin-left: 5px;
}

.formula-builder-modal {
  .operator-container {
    direction: ltr;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 10px;
    gap: 8px;
    div {
      border: 1px solid rgb(163, 163, 163);
      border-radius: 4px;
      width: 60px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      background-color: #ffff;
      color: rgb(59, 59, 59);
      &:hover {
        background-color: #eee;
        cursor: pointer;
      }
      &.disabled {
        background-color: #eee;
        color: gray;
        &:hover {
          background-color: #eee;
          cursor: not-allowed;
        }
      }
    }
  }

  .formula-builder__formula {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .mq-editable-field.mq-math-mode {
      font-size: 20px;
      font-family: "IRANYekan";
      font-style: normal;
      padding: 8px;
    }
  }
  .formula__input {
    border: 2px solid #89929e;
    padding: 12px 8px;
    flex-grow: 1;
    margin-left: 8px;
    font-size: 1.3em;
    border-radius: 8px;
    direction: ltr;
    &__error {
      border: 2px solid red;
    }
  }
  .formula__label {
    font-size: 1.5em;
  }
  .modal__title {
    direction: rtl;
    text-align: right;
    margin-top: 8px;
  }
  .formula-title {
    font-size: 1.1em;
    background-color: #eee;
    text-align: center;
    padding: 8px;
    border: 1px solid #fff;
  }
  .tbl-box {
    width: 100%;
    td {
      // background-color: #89929e;
      // border: 1px solid #eee;
      // padding: 8px;
      direction: ltr;
      text-align: center;
      padding-bottom: 2px;
      span {
        background: #108ee9;
        box-shadow: 0px 5px 5px #eee;
        width: 90%;
        display: flex;
        height: 40px;
        margin: auto;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: #fff;
        &:hover {
          cursor: pointer;
          background-color: #0086e6;
        }
      }

      &.disabled {
        &:hover {
          cursor: not-allowed !important;
        }
        span {
          background-color: #eee;
          color: rgb(156, 156, 156);
          &:hover {
            cursor: not-allowed !important;
          }
        }
      }
    }
  }
}

.input-color {
  position: relative;
  &:hover {
    cursor: pointer;
  }
  .ant-form-item {
    margin-bottom: 5px !important;
  }
  .iconColor {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: red;
    border: 1px solid #cfd5dd;
  }
  .ant-input-group-addon {
    background-color: unset;
    border-left: 0px;
  }
  &__popover {
    position: absolute;
    z-index: 2;
    // bottom: 5px;
  }
  &__cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.modal-widget-spec {
  direction: rtl;
  .ant-modal-header {
    background-color: #3c4756;
    .ant-modal-title, button {
      color: #fff;
    }
  }
  
  .ant-modal-body {
    padding-top: 10px;
  }
  .ant-modal-close {
    left: 0px;
    right: unset;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .spec__body {
  }
  .spec__campaign {
    padding: 15px 25px;
    background-color: #f5f7fa;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .spec__title {
      span {
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
        color: red;
        font-size: 1.2em;
        font-weight: bolder;
      }
    }
  }

  .spec__table {
    width: 100%;
    tr{
      border-bottom: 1px solid #eee;
      &:last-child {
        border: 0px;
      }
    }
    td {
      &:first-child {
        width: 120px;
      }
      padding: 15px 10px;
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;