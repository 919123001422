.carousel-webstores {
  .website_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }
  .website_item {
    border: 1px solid #eee;
    border-radius: 8px;
    width: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      cursor: pointer;
      border-color: #468bff;
    }
    &.active {
      border-color: #468bff;
    }
    &__snap-item {
      background-color: #fff;
    }
    .w_img {
      padding: 10px;
      margin: 10px 0px;
      .ant-image {
        height: 100px !important;
        display: flex;
        align-items: center;
        justify-self: center;
      }
    }
    .w_button {
      background-color: #EBEEF2;
      width: 140px;
      text-align: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 8px 6px;

      height: 39px;
      overflow: hidden;
      line-height: 26px;
    }
  }
}

.carousel-help {
  p {
    // font-weight: bold;
    padding-bottom: 8px;
  }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;