@import './../../app/theme.less';

.widget-ui-config {
  .widget__box {
    border: 20px solid #42474f;
    border-radius: 25px;
    height: 520px;
    width: 100%;
    margin-bottom: 50px;
    // padding: 10px;
    &__toolbar {
      background-image: url("./../../assets/images/chrome-bar-left.png"),
        url("./../../assets/images/chrome-bar-right2.png"),
        url("./../../assets/images/chrome-bar-center.png");
      background-size: auto 50px, auto 50px, auto 50px;
      background-position: left, right, center;
      height: 50px;
      background-repeat: no-repeat, no-repeat, repeat;
    }
    &__body {
      height: 80%;
      overflow-y: hidden;
      .inner_body {
        transform: scale(0.9);
        .react-multi-carousel-track  {
          transform: none !important;
        }
        .product__title {
          height: auto !important;
          .ant-typography {
            -webkit-line-clamp: 1 !important;
            margin: 0px !important;
          }
        }
        .product__price {
          margin: 10px 0 !important;
        }
        .product__thump {
          height: 210px !important;
        }
      }
    }
    &__toolbox {
      border: 1px solid #e0e6ec;
      border-radius: 8px;
      & > div {
        padding: 12px 16px;
        margin: 12px 0;
        border-bottom: 1px solid #e0e6ec;
        &:last-child {
          border-bottom: 0px;
        }
      }
      h2 {
        color: @gray-new;
        font-size: 14px;
        padding: 10px 0;
      }
      .box-widget__name {
        margin-bottom: 8px;
      }
      .label-custom {
        display: inline-block;
        color: @black;
        padding-bottom: 2px;
      }
      .box-widget__color {
        display: flex;
        gap: 5px;
        padding-bottom: 20px;
      }
      .config__list {
        display: grid !important;
        label {
          padding: 5px 0px;
        }
      }
    }
    
    &__switch {
      display: flex;
      gap: 5px;
    }
    &.mobile_view {
      width: 320px !important;
      margin: auto;
      border: 10px solid #42474f;
      border-radius: 25px;
      .widget__box__toolbar {
        border-radius: 15px;
      }
    }
    .ant-card-body {
        padding: 10px 0px;
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;