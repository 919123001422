.select-format-place {
  .block {
    border: 1px solid #e4e7eb;
    border-radius: 16px;
    padding: 8px;
    height: 100%;
    &:hover {
      cursor: pointer;
      border-color: #6a4c93;
      background-color: #ffffff;
    }
    &.active {
      border-color: #9170be;
    }
    &__image {
      width: 100%;
      background-color: #f0f1f6;
      border-radius: 8px;
      img {
        width: 100%;
      }
    }
    &__footer {
      padding: 9px;
    }
    &__title {
      padding: 10px 0;
      color: #353f4a;
      font-size: calc(1rem - 2px);
      font-weight: bold;
    }
    &__content {
      color: #747c87;
      //   font-size: 12px;
      text-align: justify;
      font-size: calc(1rem - 4px);
    }
    &--disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &--readonly {
      cursor: not-allowed;
      &:hover {
        cursor: auto !important;
      }
      &:not(.active) {
        &:hover {
          border-color: #eee;
          background-color: #ffffff;
        }
      }
    }
  }
  .ant-card-head-title {
    p {
      margin: 0px;
      margin-top: 12px;
      font-size: calc(1rem - 2px);
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;