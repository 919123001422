@import './../../app/theme.less';

.inputUpload {
  p {
    font-size: .9em !important;
  }
}

.uploaderError {
  border: 1px solid red !important;
}

.ant-upload-drag {
  background: #fff !important;
}

.text-editor {
  &__tag {
    margin: 2px !important;

    &:hover {
      cursor: pointer;
    }
  }

  .editor_variables {
    padding: 5px;
    border: 1px solid #eee;
    // background: #eee;
    border-radius: 10px;
    margin-bottom: 5px;
  }
}

.select-before {
  direction: ltr;

  &__option {
    direction: ltr;
  }
}

.jalaali-date-input, .jalaali-date-range-input{
  min-width: auto;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 40px;
  input, .icon{
    font-family: IRANYekan;
    font-variation-settings: "wght" 400, "dots" 1 !important;
    color: @text-color;
    font-size: 14px;
    &::placeholder {
      color: #bfbfbf;
    }
  }
}
.range-input-wrapper:not(.picker-input-error):not( .picker-input-disabled ):hover{
  border-color: #468bff ;
}
.jalaali-date-range-input {
  height: 37px;
}
.popover-panel-range, .popover-panel-date {
  border-radius: 8px;
}
.panel-range-jalaali, .panel-jalaali{
  font-family: IRANYekan;
  font-variation-settings: "wght" 400, "dots" 1 !important;
}

.day-label-bar{
  min-height: 31px;
  .day-label-bar-inner-rtl {
    border-radius: 6px;
    min-height: 31px;
    align-items: flex-start;
  }
}

.day-item-outer {
  .today, .hovered:not(.disabled):hover, .highlight{
    border-radius: 6px;
  }
  .day, .day-item-chain{
    height: 31px;
  }
}

.popover-panel-date {
  .day{
    overflow: auto;
  }
}
.range-day-panel-item {
  .day-item-outer {
    .start-tail-highlight{
      border-radius: 0 6px 6px 0;
    }
    .end-tail-highlight{
      border-radius: 6px 0 0 6px;
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;