@import "./../../../app/theme.less";

.toolbar-lang {
  ul {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 10px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;

    li {
      list-style-type: none;
      flex-grow: 1;
      text-align: center;
      padding: 12px 6px;
      color: #353f4a;

      img {
        width: 20px;
      }
      &.selected {
        background: #f2eefb;
        border-bottom: 2px solid @primary-color;
      }
      // &.disabled {
      //   background-color: #eee;
      //   opacity: .7;
      // }
      &:first-child:hover {
        cursor: pointer !important;
      }
      &:nth-child(n + 1):hover {
        cursor: not-allowed;
      }
    }
  }
}
.widget-builder {
  &__code-area {
    max-width: 800px;
    margin: auto;

    .code-area {
      padding: 20px 15px;
      font-size: 1em;
      color: #000;
    }
  }
  &__tabs {
    background-color: #fff;
    padding: 0px 10px !important;
    .ant-tabs-nav {
      margin: 0 !important;
    }
  }
  &__content {
    margin-top: 15px;
  }
  &__header {
    b {
      // padding: 8px;
      // background-color: #468BFF;
      border: 2px solid #eee;
      border-radius: 50px;
      padding: 0 10px;
      // width: 28px;
      // height: 28px;
      font-size: 1.5em;
      text-align: center;
      display: inline-block;
      display: none;
      color: #468bff;
      margin: 0 2px;
      margin-left: 6px;
    }
  }
  &__footer {
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 16px 24px;
    align-items: center;
    box-shadow: 0px -6px 10px rgba(34, 68, 112, 0.06);
    gap: 24px;
    .button_area {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .selected_area {
      font-size: @font-size-sm;
      button {
        font-size: @font-size-sm;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 5px;
      }
      b {
        padding: 0 4px;
        background-color: #eee;
        border-radius: 10px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
        background-color: #468bff;
        color: #fff;

        &.active {
          animation: rotate 0.3s infinite;
          font-size: 1.5em;
          zoom: 130%;
          color: #fff;
        }

        @keyframes rotate {
          0% {
            transform: rotate(-35deg);
          }
          100% {
            transform: rotate(35deg);
          }
        }
      }
    }
  }
  .switch-inline {
    display: flex;
    align-items: center;
    label {
      margin: 0 5px;
    }
  }
  &__step-success {
    text-align: center;
  }
  .inline-tag {
    display: flex;
    gap: 10px;
    .ant-form-item {
      width: 100%;
      .ant-form-item-control {
        min-width: 300px;
      }
    }
  }
  .wp_wrapper {
    margin-bottom: 12px;
  }
}

.widget-builder__footer{
  padding-left: 92px !important;
  z-index: 1000;
}

 .ant-btn-primary[disabled]:hover{
  color: white !important;
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;