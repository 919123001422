@import '../../../../app/theme.less';

.product_item {
  .product__thump {
    width: 100%;
    height: 200px;
    // min-height: 210px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    img {
      width: 80%;
      height: 100%;
    }
  }

  .product__box {
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 2em;
    text-align: center;
  }

  .product__title {
    color: #747c87;
    height: 55px;
    text-align: center;
    overflow: visible;
    max-width: 233px;
    // line-height: 2.5;
    div {
      margin-bottom: 0px !important;
    }
  }

  .product__price {
    margin-top: 5px;
    margin-bottom: 10px;
    height: 45px;
    line-height: 1.7em;
    &__old {
      font-size: 12px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      gap: 5px;
      align-items: center;
      b{ 
        background-color: #EE394D;
        border-radius: 8px;
        padding: 0px 7px;
        color: #fff;
        font-size: 10px;
        line-height: 18px;
      }
      span {
        color: #ADB5C0;
        text-decoration: line-through;
      }
    }
    &__current {
      color: #000;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .product__checkbox {
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .product_discount {
    position: absolute;
    left: 9px;
    top: 1px;
    background: #e33979;
    z-index: 5;
    border-radius: 8px 0 30px;
    color: #fff;
    width: 35px;
    height: 30px;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product__button {
    padding-bottom: 12px;
  }

  a.ant-btn {
    border: 1px solid #ee394d;
    color: #ee394d;
    &:hover {
      border: 1px solid #ee394d;
      background-color: #ee394d;
      color: #fff;
    }
  }

  .ant-card {
    margin: 0px !important;
  }
  
  .unavailable {
    color: #747c87;
  }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;