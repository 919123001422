@import "./../../app/theme.less";

.sidebar-helium {
  background-color: @sidebar-color !important;
  position: sticky;
  height: 100vh;
  overflow-y: auto;
  top: 0px;
  // right: 0px;

  z-index: 10;
  .menu_logo {
    display: flex;
    justify-content: space-between;
    padding: 24px 22px;
    height: 72px;
    border-bottom: 1px solid #50515d;

    .logo {
      height: 25px;
      padding-left: 10px;
    }

    &--collapsed {
      display: flex;
      justify-content: center;
    }
  }

  .main_menu {
    background-color: @sidebar-color !important;
    color: #eee !important;
    border: 0 !important;
    padding: 0px;
    position: sticky;

    &__flat {
      padding-top: 32px !important;
      .section__wrapper {
        border: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .ant-menu-item {
    border-radius: 8px;
    .adt {
      font-size: 1.5em;
    }
    .ant-menu-item-icon {
      display: flex;
      align-items: center;
    }
    a {
      color: @sidebar-color-link !important;
    }

    &::after {
      border: 0 !important;
    }

    &:hover {
      background-color: @sidebar-color-link_bg;
    }
  }

  .submenu-item {
    padding-right: 28px !important;
    margin: 0;

    .ant-menu-item-icon {
      color: @sidebar-color-link !important;
      opacity: 1;
      font-size: 8px;
    }
  }

  .ant-menu-item-icon {
    color: @sidebar-color-link !important;
    opacity: 1;
  }

  .ant-menu-item-selected {
    a {
      color: @layout-sider-active-color !important;
      opacity: 1;
    }

    background-color: #2a446c !important;

    ::before {
      color: @layout-sider-active-color !important;
    }
  }

  .ant-menu-submenu-arrow,
  .ant-menu-submenu-title {
    color: #fff !important;
  }

  .ant-menu-submenu-active {
    color: @layout-sider-active-color !important;
  }

  .ant-menu-title-content {
    margin: 0px !important;
  }

  .ant-menu-submenu-open {
    background-color: #2a446c;
    border-radius: 8px;

    ul {
      background-color: #2a446c;

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .submenu_icon {
    margin-right: 15px;

    &:active {
      color: @layout-sider-active-color !important;
    }
  }

  .section {
    &__wrapper {
      padding-bottom: @padding-sm;
      border-bottom: 1px solid #50637b;
      &__clear {
        padding-top: 12px;
        border: 0px;
      }
      &:last-child {
        border-bottom: 0px;
      }
      .menu__active {
        background-color: @primary-color;
        border-radius: 10px;
        .adt {
          font-weight: bold;
        }
        a {
          color: #fff !important;
        }
      }
      .ant-menu-item {
        padding: @padding-lg @padding-sm !important;
        width: 90%;
        margin: auto;
        &:active {
          background-color: @sidebar-color;
        }
      }
    }
    &__title {
      color: rgba(255, 255, 255, 0.6);
      font-size: @font-size-sm;
      padding: @padding-lg !important;
    }
  }

  &--collapsed {
    .main_menu {
      margin-top: 8px;
    }
    .section__title,
    .ant-menu-title-content {
      display: none;
    }
    
    .ant-icon svg {
      width: 100px;
    }
    .ant-menu-item-icon {
      margin: 0px !important;
    }

    .ant-menu-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75% !important;
    }

    .SidebarIcon {
      padding: 0px;
      & > div > div {
        justify-content: center;
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.section__title__news{
  color: #ffffff;
  background-color: #F73834;
  border-radius: 25%/50%;
  padding: 0.5px 5px;
  font-size: 10px;
  margin: 0 5px;
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;