.pop-banner-config {
  .device {
    position: relative;
  }

  .any {
    right: 40%;
    left: auto;
    top: 40%;
    width: 150px;
    height: 150px;
  }

  .box-preview__widget {
    position: absolute;
    .any;
    &.Any {
      .any;
    }
    &.TopRight {
      top: 10px;
      right: 10px;
      left: unset;
    }
    &.TopLeft {
      top: 10px;
      left: 10px;
      right: unset;
    }
    &.BottomLeft {
      bottom: 10px;
      left: 10px;
      right: unset;
    }
    &.BottomRight {
      bottom: 10px;
      right: 10px;
      left: unset;
    }
    &.Top {
      top: 10px;
      margin: auto;
    }
    &.Bottom {
      bottom: 10px;
      margin: auto;
    }
    &.Right {
      right: 10px;
      top: 25%;
    }
    &.Left {
      left: 10px;
      right: unset;
      top: 25%;
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;