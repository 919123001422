@import "./../../app/theme.less";

.modal-title{
  color: @info-color;
}

.modal-main-text{
  color : black
}

.tbl_b {
  &__header-dimen {
    @media (max-width: @screen-md) {
      width: 150px !important;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 150px 100px;

    margin-bottom: 8px;

    & > div {
      border: 1px solid #eee;
      width: 100%;
      padding: 8px;

      &:first-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:last-child {
        margin-right: 8px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    .row {
      &__remove {
        color: red;
        font-size: 1.5em;
        width: auto;
        padding: 0 10px;
        display: flex;
        align-items: center;
      }

      &__add {
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 0 10px;
        text-align: center;
        font-size: 1.5em;
        // width: 100px;
        width: auto;
        border-style: dashed;
        border-radius: 8px;
        border-width: 2px;
        color: @primary-color;

        .btn_delete {
          color: #c95250;
        }
      }
    }
  }

  &__header_add {
    border: 0px !important;
    // display: flex;
  }

  &_title {
    & > div {
      max-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .min_shape {
    border: 2px solid #eee;
    box-shadow: 1px 1px 5px #eee;
    display: inline;
    position: absolute;
    left: 10px;

    &.dimen_ {
      &_equal {
        padding: 8px 8px;
      }

      &_horizontal {
        padding: 10px 6px;
      }

      &_vertical {
        padding: 6px 10px;
      }
    }
  }

  .cell__dimen {
    position: relative;

    @media (max-width: @screen-md) {
      width: 150px;
    }

    .cell_span {
      display: flex;
      flex-direction: row-reverse;
      direction: ltr;
    }
  }
}

.banner {
  .ant-radio-button-wrapper-checked {
    color: #fff !important;
  }
}

.alert {
  &__show {
    margin-right: 8px;
    color: @link-color;
    cursor: pointer;
  }
}

.terms__modal {
  .ant-modal-body {
    padding: 12px !important;
  }

  .contract-text {
    height: 330px;
    padding: 0 16px;
    overflow-y: scroll;

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    a,
    span {
      font-size: 0.95em !important;
      font-family: IRANYekan !important;
    }

    p {
      text-align: justify !important;
    }

    button {
      display: none !important;
    }

    .panel-heading {
      border: 0px !important;
      margin: 0px !important;
      padding-right: 0px !important;
    }

    .panel-body {
      p {
        padding: 0px !important;

        span[style="font: 7pt Samim;"] {
          display: none !important;
        }

        // margin: 0px !important;
        // margin-right: 24px !important;
        margin-bottom: 8px !important;
      }
    }
  }
}

.modal-help {
  .body-m {
    text-align: justify;
    color: #747c87;
  }

  .btn {
    display: inline-block;
    margin: 0 5px;
  }

  .modal-pic {
    text-align: right;

    & > img {
      width: 300px;
      height: 200px;
    }
  }

  .description {
    display: inline-block;
  }
}

.tag-custom {
  &__link {
    transition: background 0.4s ease-in !important;

    &:hover {
      cursor: pointer !important;
    }
  }

  &__disabled {
    color: @disabled-color !important;
    border: 1px @disabled-color solid !important;

    &:hover {
      cursor: not-allowed !important;
    }
  }

  .ant-tag {
    border-radius: 30px !important;
    height: 26px !important;
    position: relative;
    background: @grey-2 !important;
    color: @text-text-body;
    padding: 0 8px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: unset !important;
    font-weight: 600 !important;

    display: inline-flex;
    flex-direction: row-reverse;
    gap: 8px;

    &>span[role="img"]{
      // font-size: 1.5em;
      font-size: 16px;
    }

    &-processing {
      border-radius: 30px !important;
      background-color: @info-color-back !important;
      color: @info-color;
      text-align: center !important;
      border: 1px solid;
      .anticon-edit {
        background-color: #edf3ff !important;
      }
    }

    &-success {
      background-color: @success-color-back !important;
      color: @success-color;

      .anticon-edit {
        background-color: #ecfce9;
      }
    }

    &-error {
      background-color: @error-color-back !important;
      color: @error-color;
      .anticon-edit {
        background-color: #fff0f0;
      }

      // &:hover {
      //   background-color: #FFF0F0 !important;
      // }
    }

    &-warning {
      background-color: @warning-color-back !important;
      color: @warning-color;
      .anticon-edit {
        background-color: #fdf2e6;
      }

      // &:hover {
      //   background-color: #FDF2E6 !important;
      // }
    }

    .anticon-edit {
      height: 100%;
      width: 24px;
      // position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 50px;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

.order-summary {
  margin-bottom: 16px !important;
  box-shadow: unset !important;

  &__section {
    b {
      color: #89929e;
      margin-left: 5px;
    }
    span {
      font-weight: bold;
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;