@import "./../../app/theme.less";

.page__title {
  margin: 10px 0;
  min-width: 140px;
  &__main {
    display: flex !important;
    align-items: center;
    h4 {
      margin: 0 !important;
    }
  }
  .title__helper {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 15px;
  }

  .helper__button {
    background-color: #fff;
    padding: 0px 5px;
    box-shadow: 0 0 9px 0 rgba(63, 77, 105, 0.04);
    height: 48px;
    width: 48px;
    border: 0;

    img {
      width: 90%;
    }

    @keyframes blink {
      0%,
      100% {
        opacity: 0;
      }

      /* more concise! */
      50% {
        opacity: 1;
      }
    }

    .image_on {
      display: none;
    }

    .image_off {
      display: block;
    }

    &:hover {
      .image_on {
        display: block;
      }

      .image_off {
        display: none;
      }
    }

    dot {
      width: 5px;
      height: 5px;
      background-color: @secondary-color;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 5px;
      right: 5px;
      animation: blink 2s infinite;
    }
  }
}

#slider-menu {
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  z-index: 10;
  background-color: #fff;
  // padding: 16px;
  box-shadow: 0 2px 7px 0 rgb(34 68 112 / 7%);

  .menu-title {
    background-color: @layout-body-background;
    padding: 20px 16px;
    font-size: 1.3em;
    display: flex;
    align-items: center;

    & > div {
      margin-right: 8px;
    }
  }

  .menu-content {
    padding: 16px;
    height: 100%;
    overflow-y: auto;
    padding: 8px;
  }
}

.no-display {
  @media (max-width: @screen-md) {
    display: none !important;
  }
}

.mini-logo {
  width: 100px;
}

.logo {
  width: 80px;
  margin: 10px 0;
}

//ltrStyles
.ltr_cls {
  .table-c {
    .ant-pagination-total-text {
      right: unset;
      left: 6px;
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;