@import './../../app/theme.less';

.help_tour {
    .p {
        margin-bottom: 20px;
    }
    .tour-grid {
        height: 58px;
        display: flex;
        padding: 0 8px;
        align-items: center;
        color: @dark-gray;
        border-radius: 8px;
        border: @border-color 1px solid;
        &:hover {
            cursor: pointer;
            border: @primary-color 1px solid;
            color: @primary-color;
        }
        &__disabled {
            color: #ddd;
            &:hover {
                cursor: default;
            }
        }
        &__more {
            padding: 10px;
            background: #F4F6F8;
            border-radius: 8px;
            margin-top: 16px;
            color: @dark-gray;
        }
        &__icon {
            text-align: right;
            margin-left: 10px;
            color: @primary-color;
        }
    }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;