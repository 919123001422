.main-config {
  height: 468px;
  border: 1px solid #e4e7eb;
  border-radius: 12px;
  .ant-form-item-control-input {
    max-width: 300px !important;
  }
  .input_commission_price {
    width: 100%;
  }
  .ant-col-xs-14 {
    padding: 0px !important;
  }
  .ant-col-xs-10 {
    max-height: 516px;
    overflow-y: scroll;
    height: 100%;
  }
  .ant-col-xs-10 {
    position: relative;
  }
  .form_footer {
    position: sticky;
    bottom: 0px;
    right: 0px;
    padding: 10px 0;
    font-size: small;
    background-color: #fff;
    // border: 1px solid #eee;
    width: 100%;
  }
}

.place-builder {
  &__body {
    margin: 24px;
  }
  .subtitle {
    font-size: medium;
    margin: 0px;
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;