@import "./../../app/theme.less";

.statistics-cards {
  .ant-col-md-5 {
    max-width: 20% !important;
  }

  @media (max-width: @screen-sm) {
    margin-top: 20px;

    .ant-col-md-5 {
      max-width: 100% !important;
    }
  }

  .card-static {
    background-color: #fff;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    display: flex;
    box-shadow: 0px 0px 13px rgba(63, 77, 105, 0.04);
    align-items: center;

    .card_icon {
      background: rgba(255, 255, 255, 0.2);
      // padding: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 63px;
      display: flex;
      align-items: center;
      font-size: 1.4em;
      width: 35px;
      justify-content: center;
    }

    .card_body {
      padding-right: 10px;

      @media (max-width: @screen-sm) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
      }

      .body__value {
        font-size: @font-size-base + 6px;
        font-weight: bold;

        @media (max-width: @screen-sm) {
          margin-left: 20px;
        }
      }

      .body__name {
        font-size: @font-size-base - 1px;
      }
    }

    &.card-static {
      &--blue {
        background: linear-gradient(267.04deg, #2a59e1 21.85%, #1190ec 98.9%);
      }

      &--pink {
        background: linear-gradient(265.6deg, #d72c56 0%, #ff6c91 99.57%);
      }

      &--purple {
        background: linear-gradient(266.98deg, #962ee8 22.13%, #c464ff 98.89%);
      }

      &--orange {
        background: linear-gradient(263.99deg, #e46036 22.8%, #ff8b62 99.18%);
      }

      &--green {
        background: linear-gradient(266.67deg, #0aa09b 22.37%, #23cec8 99.71%);
      }
    }
  }
}

.pie-chart-commission {
  display: flex;
  align-items: center;

  @media (max-width: @screen-sm) {
    display: inline;
  }

  .list {
    width: 40%;

    @media (max-width: @screen-sm) {
      width: 100%;
    }

    ul {
      width: 100%;
      list-style-type: none;

      li {
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        padding-bottom: 10px;

        &:last-child {
          border: 0;
        }

        h3 {
          padding: 0;
        }

        .sub {
          color: @gray-new;
          font-size: 0.9em;

          &::before {
            margin-left: 8px;
            content: " ";
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: @primary-color;
            border-radius: 10px;
          }

          &.sub {
            &_2 {
              &::before {
                background-color: #f13967;
              }
            }

            &_3 {
              &::before {
                background-color: #28b8b0;
              }
            }
          }
        }
      }
    }
  }

  .pie {
    direction: ltr;
    width: 60%;
  }

  .pie-chart__merchant {
  }
}

.view-clicks-chart {
  .title {
    display: flex;
    align-items: center;

    img {
      margin-left: 5px;
    }
  }

  .ant-card-body {
    padding: 12px;
  }
}

.commission-chart {
  .title {
    display: flex;
    align-items: center;

    img {
      margin-left: 5px;
    }
  }
}

.top-notice {
  .title {
    display: flex;
    align-items: center;

    img {
      margin-left: 5px;
    }
  }

  ul {
    height: 150px;
    overflow: auto;
    padding: 0px;

    li {
      list-style-type: none;
      border-bottom: 1px solid #eee;
      padding: 15px 0;
      color: #747c87;

      b {
        margin-left: 10px;
      }

      &:first-child {
        b {
          color: #28b8b0;
        }
      }

      &:nth-child(2) {
        b {
          color: #ae47ff;
        }
      }

      &:nth-child(3) {
        b {
          color: #468bff;
        }
      }
    }
  }
}

.my-media {
  .title {
    display: flex;
    align-items: center;

    img {
      margin-left: 5px;
    }
  }

  &__content {
    height: 165px;
    overflow: auto;
  }

  table {
    width: 100% !important;

    tr {
      border-bottom: 1px solid #eee;

      th {
        color: #000 !important;
      }

      th,
      td {
        text-align: right;
        padding: 10px;
        color: #747c87;
      }
    }
  }
}

.intro-affilio {
  &__posts {
    .card-item {
      img {
        width: 100%;
        border-radius: 10px;
        height: 250px;
      }

      .date {
        padding: 10px 0;
        font-size: 1.1em;
        color: @gray-new;
      }

      border: 1px solid #eee;
      padding: 20px;
      margin: 0 10px;
      border-radius: 10px;
      // background-color: red;
    }
  }
}

.order-type-category-chart {
  direction: ltr;
}

.order-link-chart {
  &__footer {
    margin-top: 20px;

    ul {
      padding: 0px 30px;
      list-style-type: none;
      display: flex;
      justify-content: space-between;

      li {
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50px;
          text-align: center;
          margin: auto;
          margin-bottom: 5px;

          &__widget {
            background-color: #d72c56;
          }

          &__banner {
            background-color: #ae47ff;
          }

          &__link {
            background-color: #1190ec;
          }
        }

        text-align: center;
      }
    }
  }
}

.order-media-chart {
  // direction: ltr;

  &__footer {
    margin-top: 20px;

    ul {
      padding: 0px 30px;
      list-style-type: none;
      display: flex;
      justify-content: space-between;

      li {
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50px;
          text-align: center;
          margin: auto;
          margin-bottom: 5px;

          &__Telegram {
            background-color: #0088cc;
          }

          &__Instagram {
            background-color: #e72a68;
          }

          &__Website {
            background-color: #209e6a;
          }
        }

        text-align: center;
      }
    }
  }
}

.horizontal-chart-bar {
  .select-type {
    text-align: right;

    .ant-select {
      width: 130px;
    }
  }
}

.tile-number {
  &-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #e0e6ec;
    padding: 0px !important;
    gap: 20px;
  }

  &.row {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px dashed #e0e6ec;
    background: #fafbfc;
    height: 40px;
  }

  &.align {
    &__center {
      text-align: center;
    }

    &__right {
      text-align: right;
    }
  }

  padding: 1rem 16px;
  border-bottom: 2px dashed #e0e6ec;

  &:last-child {
    border-bottom: 0px;
  }

  .total {
    display: flex;
    align-items: center;

    h3 {
      // font-size: 1.6em;
      font-size: 1.1rem;
      font-weight: bold;
      margin: 0px;
    }

    b {
      font-size: 0.8em;
      margin: 0 5px;
      color: #a8b0bb;
    }

    .cp {
      &.color_1 {
        color: @dash-color-1;
      }

      &.color_2 {
        color: @dash-color-2;
      }

      &.color_3 {
        color: @dash-color-3;
      }

      &.color_5 {
        color: @dash-color-5;
      }

      &.color_6 {
        color: @dash-color-6;
      }

      &.color_7 {
        color: @dash-color-7;
      }

      &.color_8 {
        color: @dash-color-8;
      }

      &.color_9 {
        color: @dash-color-9;
      }
      &.color_10 {
        color: @dash-color-10;
      }
    }
  }

  .title {
    color: #747c87;
    margin: 0px;
    padding: 0px;
    direction: rtl;
  }
}

.tile-analytic {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  &-card {
    flex-grow: 1;
    margin: 0px !important;
    // height: 50%;
    &-container{
      display: flex;
      gap: 22px;
      align-items: center;
      height: 100%;
    }
    &-right{
      flex-basis: 48px;
      flex-grow: 0;
      flex-shrink: 0; 
      height: 100%;
    }
    .tac__text {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
      color: #89929E;
    }
    .tac__price {
      text-align: right;
      font-size: 22px;
      color: #474C53;
      display: flex;
      align-items: center;
      i {
        font-style: normal;
        margin-right: 5px;
        font-size: 16px;
      }
    }

    &.color_1 {
      background: rgba(40, 184, 176, 0.1);
      border: 1px solid rgba(40, 184, 176, 0.4);
      border-radius: 8px;
      .tac__text {
        color: #28b8b0;
      }
    }
    &.color_2 {
      background: rgba(255, 114, 149, 0.1);
      border: 1px solid rgba(255, 114, 149, 0.4);
      border-radius: 8px;
      .tac__text {
        color: #ff7295;
      }
    }
    &.color_3 {
      background: rgba(70, 139, 255, 0.1);
      border: 1px solid rgba(70, 139, 255, 0.4);
      border-radius: 8px;
      .tac__text {
        color: #468bff;
      }
    }
    &.color_4 {
      background: rgba(255, 129, 101, 0.1);
      border: 1px solid rgba(255, 129, 101, 0.4);
      border-radius: 8px;
      .tac__text {
        color: #ff8165;
      }
    }
    &.color_5 {
      background: rgba(166, 115, 233, 0.1);
      border: 1px solid rgba(166, 115, 233, 0.4);
      border-radius: 8px;
      .tac__text {
        color: #a673e9;
      }
    }
  }
}

.admin_commission_chart {
  .ant-card-body {
    padding: 0px;
    // padding-top: 0px;
  }

  .pie-border {
    border-left: 1px solid #e0e6ec;
    border-right: 1px solid #e0e6ec;
    padding-top: 30px;
  }

  .tile-bar {
    background-color: #fafbfc;
    display: flex;

    .tile-number {
      text-align: center;
      width: 100%;
      border: 1px dashed #ccd5df;
      border-left: 0px;

      // border-right: 0px;
      .total {
        justify-content: center;
      }
    }
  }

  .no-flex {
    display: block;
  }

  .pie-description {
    text-align: center;
    margin-top: 15px;
    color: @gray-new;
  }

  .tags-bar {
    display: flex;
    height: 30px;
    align-items: center;
    margin-top: 20px;
    margin-right: 25px;

    &.align {
      &__center {
        justify-content: center;
      }

      &__right {
        justify-content: right;
      }
    }

    .tag {
      margin-left: 15px;

      &.color_1 {
        background: lighten(@dash-color-1, 25%);
        color: @dash-color-1;
      }

      &.color_2 {
        background: lighten(@dash-color-2, 25%);
        color: @dash-color-2;
      }

      &.color_3 {
        background: lighten(@dash-color-3, 25%);
        color: @dash-color-3;
      }

      &.color_5 {
        background: lighten(@dash-color-5, 25%);
        color: #00605a;
      }

      &.color_6 {
        background: lighten(@dash-color-6, 25%);
        color: @dash-color-6;
      }

      &.color_7 {
        background: lighten(@dash-color-7, 25%);
        color: @dash-color-7;
      }

      &.color_8 {
        background: lighten(@dash-color-8, 25%);
        color: @dash-color-8;
      }
    }
  }

  p {
    font-size: 11px;
    border-radius: 6px;
    padding: 5px;
  }

  .border {
    border-bottom: 1px dashed #e0e6ec;
  }

  .pie-wrapper {
    padding: 30px 0;
    &_col {
      direction: ltr;
      text-align: center;
      font-size: 0.8em;
    }
    // border-right: 1px solid #E0E6EC;
  }
}

.top-publisher-tile-wrapper {
  margin-bottom: 40px;
  @media (max-width: @screen-sm) {
    margin-bottom: 0;
    padding: 25px;
  }

  .square {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    margin-left: 13px;
  }

  .value-wrapper {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    color: #474c53;
  }

  .text {
    color: @dark-gray;
  }
}

.bg {
  &.color_1 {
    background: @dash-color-1;
  }

  &.color_2 {
    background: @dash-color-2;
  }

  &.color_3 {
    background: @dash-color-3;
  }

  &.color_5 {
    background: @dash-color-5;
  }

  &.color_6 {
    background: @dash-color-6;
  }

  &.color_7 {
    background: @dash-color-7;
  }

  &.color_8 {
    background: @dash-color-8;
  }

  &.color_9 {
    background: @dash-color-9;
  }

  &.color_10 {
    background: #ffe4dc;
  }

  &.color_11 {
    background: #d5f1f0;
  }
}

//ltrStyles

.ltr_cls {
  .pie-chart-commission {
    .list {
      ul {
        li {
          .sub {
            &::before {
              margin-left: 0;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .statistics-cards {
    .card-static {
      .card_body {
        padding-left: 10px;
      }

      .card_icon {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .view-clicks-chart {
    .title {
      img {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }

  .commission-chart {
    .title {
      img {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }

  .top-notice {
    .title {
      img {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }

  .my-media {
    .title {
      img {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }

  .horizontal-chart-bar {
    .select-type {
      text-align: left;
    }
  }
}

.statistic-bar {
  padding: 30px;

  .column {
    padding-bottom: 4px;
    border-bottom: 2px dashed #e0e6ec;
  }

  img {
    margin-left: 10px;
  }

  .number {
    font-weight: 700;
    font-size: 20px;
    color: @dash-color-7;
  }

  .name {
    color: @dark-gray;
    font-size: 16px;
    margin-top: 6px;
  }
}

.ant-card-head {
  @media (max-width: @screen-sm) {
    min-width: 285px;
  }
}

.top_in_affilio {
  &.ant-card {
    .title {
      i {
        font-style: normal;
        color: #89929e;
        margin: 0 5px;
      }
    }
  }
  .ant-tabs-nav-wrap {
    padding-right: 15px;
    background-color: #fafbfc;
  }
  .ant-tabs-tab {
    padding: 16px 10px;
  }
}

.publisher-chart {
  flex-grow: 1;
  margin: 0px;
  padding: 0px;
  margin-top: 16px;
  margin-bottom: 0px !important;
  // &.ant-card {
  // position: relative;
  // height: auto !important;
  // }
}

.chart_final_order {
  margin-bottom: 0px !important;
  .tile-number.row {
    height: auto;
    padding: 1.2rem 22px;
  }
  .tile-number {
    padding-right: 30px;
    padding-left: 30px;
    p {
      font-size: 1em;
    }
  }
}

.card-info-dashboard {
  // padding: 20px;
  height: 100%;
  flex-grow: 1;
  .ant-card {
    margin-bottom: 0px !important;
  }
  img {
    margin-bottom: 12px;
  }
  p {
    color: #89929E;
    font-size: 16px;
  }
  span {
    color: #474c53;
    font-weight: 700;
    font-size: 22px;
  }
  i {
    font-style: normal;
    margin-right: 5px;
    font-size: 16px;
    color: #474c53;
  }
}

.commission__info {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
  gap: 20px !important;
  &__publisher {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    gap: 20px !important;
  }
}

.dashboard__order-commission-chart {
  &.ant-card {
    margin-bottom: 0px !important;
  }
  .ant-card-body {
    padding: 0px;
  }
  .ant-col-md-19 {
    padding: 20px;
  }
}

.img-empty {
  img {
    width: 50px;
  }
  .ant-empty-image {
    margin: 0px;
  }
  margin: auto !important;
  color: @gray-new;
}
.tile-analytic-card-image{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;