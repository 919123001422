// @font-face {
//   font-family: IRANYekan;
//   font-style: normal;
//   font-weight: normal;
//   font-feature-settings: normal !important;
//   font-variant: normal !important;
//   src: url("./../../yekan-bakh2/YekanBakh-Regular-v3-medium.woff")
//       format("woff"),
//     url("./../../yekan-bakh2/YekanBakh-Regular-v3-medium.woff2") format("woff2"); /* FF39+,Chrome36+, Opera24+*/
// }

// @font-face {
//   font-family: IRANYekan;
//   font-style: normal;
//   font-weight: bold;
//   font-feature-settings: normal !important;
//   font-variant: normal !important;
//   src: url("./../../yekan-bakh2/YekanBakh-Regular-v3-bold.woff") format("woff"),
//     url("./../../yekan-bakh2/YekanBakh-Regular-v3-bold.woff2") format("woff2");
// }


@font-face {
  font-family: IRANYekan;
  font-style: normal;
  font-weight: bold;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  font-variation-settings: "wght" 600, "dots" 1 !important;
  src: url("./../../yekan-bakh2/num/YekanBakhFaNum-VF.WOFF")
    format("woff") , url("./../../yekan-bakh2/num/YekanBakhFaNum-VF.woff2") format("woff2");
}


@font-face {
  font-family: IRANYekan;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  src: url("./../../yekan-bakh2/num/YekanBakhFaNum-VF.WOFF")
    format("woff") , url("./../../yekan-bakh2/num/YekanBakhFaNum-VF.woff2") format("woff2");
}

@font-face {
  font-family: IRANYekan;
  font-style: normal;
  font-weight: bold;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  src: url("./../../yekan-bakh2/num/YekanBakhFaNum-VF.WOFF")
    format("woff") , url("./../../yekan-bakh2/num/YekanBakhFaNum-VF.woff2") format("woff2");
}

// @font-face {
//   font-family: IRANYekan;
//   font-style: normal;
//   font-weight: bold;
//   src: url("./../../yekan/yekan-number/iranyekanwebregularfanum.woff")
//     format("woff");
// }


