@import './../../app/theme.less';

.widget-wrapper-builder {
    .ant-card-head {
        background-color: #f5f7fa;
        .ant-card-head-title {
        font-size: 0.9em;
        font-weight: bold;
        color: @dash-color-7;
        }
    }
    .ant-card-body {
        padding: 0px;
    }
}
.w_wrapper {
    &__extra {
        display: inline-flex;
        gap: 10px;
        align-items: center;
    }
    &__selected {
        border: 2px solid @tag-blue-text !important;
    }
    &__campaign {
        display: flex;
        align-items: center;
        background-color: #fff;
        gap: 8px;
        padding: 6px 25px;
        border-radius: 20px;
        box-shadow: 0px 0px 5px rgba(35, 55, 85, 0.08);
        b {
            color: #89929E;
        }
        img {
            width: 32px;
        }
        .camp_name {
            font-weight: bold;
            color: red;
        }
        .camp_count-down {
            font-weight: bold;
            color: #000;
            font-size: 17px;
            font-family: IRANYekanNumber;
            b {
                padding: 0 2px;
            }
        }
    }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;