@import "./../../app/theme.less";

.dashboard {
  &--page-title {
    font-size: 22px;
  }
  .ant-card {
    height: 100%;
    margin-bottom: 0px !important;
    // display: flex;
    // flex-direction: column;
  }
  .filter-header__card.ant-card {
    margin-bottom: 24px !important;
  }
}

@media print {
  .print-button,
  .ant-layout-header,
  aside {
    display: none !important;
  }
  .dashboard {
    zoom: 70%;
    .ant-form {
      .ant-row {
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
}

.g2-tooltip {
  // direction: rtl !important;
  .g2-tooltip-list {
    .g2-tooltip-list-item {
      display: flex;
      flex-direction: row-reverse;
      span {
        margin-left: 8px;
        margin-right: unset !important;
      }
      // justify-content: space-between;
      .g2-tooltip-value {
        margin: 0px !important;
        margin-right: 10px;
        float: left !important;
      }
    }
  }
}

.report-insight {
  &__table {
    width: 100%;
  }
  table {
    .up-diff {
      color: green;
    }
    .down-diff {
      color: red;
    }
    border-radius: 8px;
    border: 1px solid #eee;
    td,
    th {
      padding: 14px 8px;
    }
    td {
      font-size: 14px;
      span {
        margin: 0 1px;
      }
    }
    td:first-child {
      color: @dark-gray;
    }
    th {
      text-align: right;
    }
    tr:nth-child(even) {
      background-color: rgba(245, 247, 250, 0.8);
    }
    .info-diff {
      margin-right: 5px;
    }
  }
  .table-1 {
    display: flex;
    flex-direction: row;
    &:last-child {
      border-right: 0px;
    }
  }
}

.tile-cpo-merchant {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  // background-color: red;
  gap: 20px;
  .ant-card {
    max-height: 200px;
  }
  .tac__text {
    font-size: 16px;
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;