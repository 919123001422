@import './../../app/theme.less';

.invoices {
    table th {
        width: 140px !important;
    }
}
.invoice {
    &_card-detail {
        border: 1px dashed #BCC5D1;
        border-radius: 5px;
        padding: 16px;
        .logo {
            margin-bottom: 10px;
            width: 60%;
            // background-color: #eee;
        }
        table {
            width: 100%;
            tr td {
                padding: 10px;
                &:first-child {
                    text-align: right;
                }
                &:last-child {
                    text-align: left;
                    color: #747C87;
                    .tag-custom {
                        .ant-tag {
                            margin: 0px !important;
                        }
                    }
                }
            }
        }
    }
    &_head {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        &>div {
            min-width: 30%;
            // flex-grow: 1;
            padding: 10px 0 15px;
            // text-align: center;
            b {
                margin-left: 5px;
            }
            @media (max-width: @screen-md) {
                width: 100%;
            }
        }
    }
    &_content {
        padding: 0 20px 0 0;
    }

    &_buttons {
        text-align: left;
        margin-bottom: 0;
        @media (max-width: @screen-md) {
            margin-bottom: 15px;
        }
    }

    .ant-collapse-content-box {
        padding: 0 !important;
    }

    &_orders {
        overflow-x: scroll;
        table {
            width: 100%;
            th {
                background-color: #F5F7FA;
                padding: 10px;
                i {
                    font-size: .7em;
                    font-style: normal;
                    margin-right: 1px;
                    &::after{
                        content: ")";
                    }
                    &::before{
                        content: "(";
                    }
                }
                &:nth-child(2){
                    width: 150px;
                }
            }
            td {
                padding: 10px;
                text-align: center;
                // min-width: 100px;
                &:nth-child(2){
                    width: 150px;
                }
                // min-width: 100px;
            }
        }
        .invoice_sum {
            background-color: #F5F7FA;
        }
    }
}
.invoicePrint {
    background-color: #fff;
    width: 1300px;
    margin: auto;
    direction: rtl;
    padding: 20px;
    border-radius: 10px;
    font-size: 12px;
   
    font-family: IRANYekanNumber, IRANYekan !important;
    .title-invoice {
        text-align: center;
    }
    .row-head {
        display: flex;
        .row-right {
            flex-grow: 1;
        }
        .row-left {
            border: 1px solid #000;
            margin: 0px;
            margin-right: 2px;
            margin-bottom: 2px;
            padding: 10px;
            width: 180px;
            table {
                width: 100%;
                tr {
                    td {
                        padding: 2px;
                        &:last-child {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .row-1 {
        display: flex;
        &>div {
            border: 1px solid #000;
            margin: 0px;
            margin-left: 2px;
            margin-bottom: 2px;
            padding: 10px;
            &:first-child{
                position: relative;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:nth-child(2){
                flex-grow: 1;
                table {
                    tr {
                        td {
                            min-width: 190px;
                            padding: 7px;
                        }
                    }
                }
            }
            &:last-child{
                position: relative;
                width: 25%;
            }
        }
        .right-title {
            transform: rotate(90deg);
            position: absolute;
            // top: 34px;
        }
        table {
            width: 100%;
            tr{
                td{
                    b {
                        margin-left: 5px;
                        &::after {
                            content: ":";
                        }
                    }
                }
            }
        }
    }
    .row-items {
        -webkit-print-color-adjust: exact !important;
        table {
            width: 100%;
            tr {
                th {
                    background-color: #eee;
                    max-width: 150px;
                }
                td,th {
                    border: 1px solid #000;
                    padding: 5px;
                    text-align: center;
                }
                td{
                    &.big-td{
                        text-align: right;
                        padding-right: 10px;
                        width: 500px;
                    }
                }
                .big-th{
                    text-align: right;
                    padding-right: 10px;
                    border-left: 0;
                }
                .hid {
                    border-right: 0;
                    border-left: 0;
                }
            }
            tr.sum-2,
            tr.sum-3{
                td{
                    background-color: #eee;
                }
            }
        }
    }
    .footer-total {
        table {
            width: 100%;
            tr {
                td {
                    padding: 50px;
                    padding-right: 0;
                }
            }
        }
    }
    .print-btn {
        text-align: left;
        margin-bottom: 5px;
    }
    @media print {
        .print-btn {
            display: none;
        }
    }
}


.ltr_cls {
    .invoice {
        &_content {
            padding: 0 0 0 20px !important;
        }
        &_buttons {
            text-align: right;
        }
    }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;