@import './../../app/theme.less';

.filter-header {
    &__card {
      .ant-form {
        margin-bottom: 0px;
      }
    }
    .ant-form-item {
      margin-bottom: 0px !important;
    }

    .filter__period {
      display: flex;
      column-gap: 10px;
      flex-wrap: nowrap;
  
      // !TODO NEXT ADD Animation
      // -moz-animation-delay: 3.5s;
      // -webkit-animation-delay: 3.5s;
      // -o-animation-delay: 3.5s;
      // animation-delay: 3.5s;
  
      .filter__date-range {
        display: flex;
        align-items: center;
  
        .ant-picker {
          border: 0px;
        }
  
        .ant-btn {
          .close-circle{
            line-height: 0.5;
          }
          &:last-child {
            border: 0;
            border: 1px solid #d9d9d9;
            border-radius: 8px 0px 0 8px;
            margin-right: -5px;
            height: 37px;
            display: flex;
            align-items: center;
          }
        }
      }
  
      .ant-form-item {
        @media (max-width: @screen-sm) {
          margin-bottom: 10px;
        }
      }
  
      .ant-radio-button-wrapper {
        @media (max-width: @screen-sm) {
          width: 124px;
        }
      }
    }
  
    .ant-radio-button-wrapper-checked {
      color: #fff !important;
      background-color: #ebf1fe;
      border-color: #d9d9d9;
  
      &::before {
        background-color: #d8d8d8;
      }
    }
  
    // .icon {
    //   font-size: 16px;
    //   background-color: @primary-color;
    //   color: white;
    //   width: 32px;
    //   height: 32px;
    //   text-align: center;
    //   line-height: 34px;
    //   border-radius: 6px;
    // }
    .filter_tag {
      background: rgba(137, 146, 158, 0.12);
      border-radius: 20px;
      padding: 3px 12px 4px;
      border: 0px;
    }
  }
  .ant-btn-primary{
    border: none !important;
    font-feature-settings: normal !important;
  font-variant: normal !important;
  }
  .ant-btn-primary:hover{
    background-color: rgb(36 , 98 , 202) !important;
    border: none !important
  }

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;