@import "@/app/ant-custom.less";
@color-yellow: #fff072;
.widget-income {
  background: linear-gradient(
    288.61deg,
    #009f60 1.27%,
    #007d4b 98.16%
  ) !important;
  color: #fff;
  position: relative;

  .wallet-image {
    left: 20px;
    top: 20px;
    position: absolute;
  }
  .widget_title {
    height: 60px;
  }
  .divider {
    opacity: 0.3;
    border-bottom: 1.2px dashed #fff;
    margin-top: 0;
    margin-bottom: 12px;
  }
  .percent {
    background-color: #fde3e1;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    width: 40px;
    color: red;
    text-align: center;
    direction: ltr;
    font-size: @font-size-sm;
  }

  .income-title {
    color: @color-yellow;
    font-size: large;
    font-weight: bold;
  }
  .income-cost {
    // font-weight: bold;
    font-size: @font-size-lg * 2;
    color: #fff;
    i {
      font-style: normal;
      font-size: large;
    }
  }
  .chart-compare {
    .title {
      color: #fff;
      font-weight: bold;
    }
  }
  .average-in-come {
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 12px 24px;
    border-radius: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: bold;
      color: @color-yellow;
    }
    .price {
      font-size: @font-size-sm * 2;
      color: #fff;
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;