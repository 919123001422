@import "./../../app/theme.less";
@import "./../../app/mixins.less";

.site-layout-header {
  height: 72px;
  box-shadow: 0px 0px 20px 0px rgba(34, 68, 112, 0.16);
  display: flex;
  align-items: center;
  z-index: 1;
}
.site-header {
  .ant-page-header-heading-title {
    font-size: @font-size-base;
  }

  .mixin-max-content();
  padding: unset;

  flex-grow: 1;
  padding: 0 24px;
  .appbar__icons {
    font-size: 20px;
    color: #747c87;
    border-left: 1px solid #eee;
    padding-left: 10px;
  }

  @media (max-width: @screen-md) {
    .ant-page-header-heading {
      margin: 0px !important;
    }
  }

  &__link {
    color: #000;
  }

  .ant-page-header-heading-extra {
    display: flex;
    align-items: center;
  }

  &__help {
    background: #f4f6f8;
    color: @dark-gray;
    border: none;
    padding: 0 8px;
  }

  &__icon {
    margin-left: 8px;
  }
}

.profile-menu {
  .menu-name {
    &:hover {
      cursor: text;
      background-color: #fff;
    }
  }

  .menu-user-name {
    background-color: #F2F2F2;
    margin: 6px 12px;
    border-radius: 6px;
    color: #010101;
  }
}

.btn-profile {
  border: 1px solid #e4e7eb;
  border-radius: 10px;
  padding: 6px;
  padding-right: 12px;
  text-align: center;
  font-size: calc(@font-size-base - 1px);
  .ant-avatar {
    margin: 0px 8px !important;
  }

  &:hover {
    cursor: pointer;
  }


  @media (max-width: @screen-md) {
    padding-right: 6px;

    .ant-avatar {
      margin: 0px !important;
    }
  }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;