@import "./../../app/theme.less";

.form-search-bar {
  margin-bottom: 16px !important;

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .adv-search {
    .ant-picker-range {
      width: 100%;
    }

    .submit-row {
      @media (max-width: @screen-md) {
        margin-top: 25px;
      }
      button {
        font-size: small;
      }
      .action-button {
        display: flex;
        gap: 12px;
      }
      .search_btn:hover{
        background-color: rgb(229, 238, 255) !important;
      }
      .search_btn{
        margin-right: 3px !important;
      }
    }

    .reset-icon {
      text-align: left;
    }
  }
  .search-more {
    button {
      background-color: @primary-color-light-2;
      border: none !important;
    }
    i {
      color: @primary-color;
      font-size: 24px;
      margin-top: 3px;
      &.close {
        rotate: 180deg;
      }
    }
  }
}


.form-search-bar .adv-search .submit-row .search_btn{
  color: @primary-color !important;
  border: 1px solid @primary-color !important
}

.delete_filters{
  padding: 3px !important;
}

.delete_filters:hover{
  color: @primary-color !important;
  background-color: transparent !important;
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;