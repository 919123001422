@import "./../app/theme.less";

.live-viewer {
  background-color: #f5f7fa;
  padding: 0px;
  height: 100%;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;

  .device {
    height: 100%;
    background-image: url("../assets/images/devices/laptop.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center top;

    &-selector {
      display: flex;
      justify-content: center;
      gap: 12px;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;

      div {
        padding: 10px;
        border-radius: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5em;
        &:hover {
          cursor: pointer;
        }
        &.active {
          background-color: #fff;
          border: 1px solid @primary-color;
          color: @primary-color;
        }
      }
    }

    .browser {
      width: 579px;
      height: 365px;
      margin: auto;
      position: relative;
      top: 30px;
      zoom: 0.7;
    }
    .device-header {
      display: none;
      background-image: url("./../assets/images/chrome-bar-left.png"),
        url("./../assets/images/chrome-bar-right2.png"),
        url("./../assets/images/chrome-bar-center.png");
      background-size: auto 50px, auto 50px, auto 50px;
      background-position: left, right, center;
      height: 50px;
      background-repeat: no-repeat, no-repeat, repeat;
    }
    &.DESKTOP {
    }
    &.TABLET {
      background-image: url("../assets/images/devices/tablet.png");
      background-position: bottom;

      .browser {
        top: 32px;
        width: 495px;
        height: 94%;
        right: 2px;
      }
      .format-fixed {
        top: 11px !important;
      }
    }
    &.MOBILE {
      background-image: url("../assets/images/devices/mobile.png");
      background-position: bottom;
      .browser {
        top: 45px;
        width: 325px;
        height: 94%;
      }
      .format-fixed {
        // top: 10px !important;
      }
    }
  }

  .main-format {
    padding: 4vh 0;
    height: calc(100% - 70px);
    // overflow-y: scroll;
    overflow: hidden;
    .box-preview {
      width: 70%;
      margin: auto;
    }
  }
  .skeleton {
    height: 5px;
    background-color: #dde1e6;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
    &.img {
      height: 45px;
      margin-bottom: 5px;
    }
  }
  h2.skeleton {
    height: 6px;
    width: 50%;
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;