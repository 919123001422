@import "./../../app/theme.less";

.table-wrapper {
  border : 1px solid @grey-5 !important;
  border-radius: 14px;
  z-index: 1000;
  overflow: hidden;
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    // border: 1px solid #eee;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    // border-bottom: 10px solid #eee;
    .search-filter {
      .adt {
        font-size: 1.5em;
        color: @primary-color;
        rotate: 90deg;
      }
      input {
        min-width: 250px;
      }
    }
    .search-action {
      .adt {
        font-size: 1.5em;
        color: @text-text-hint;
      }
    }
  }
}

.box-filter {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 200px;
    overflow-y: auto;
  }
}

.table-c {
  tr {
    background-color: #fff;
  }

  td,
  th {
    border-bottom: 1px solid @layout-body-background !important;
    background-color: #fff !important;

    &::before {
      display: none;
    }
  }

  th {
    color: @black !important;
    font-weight: bold !important;
    // background-color: #f5f7fa !important;
  
  }

  .middle {
    text-align: center;
  }

  .ant-pagination-total-text {
    position: absolute;
    // bottom: 12px;
    padding-top: 2px;
    right: 6px;
    color: #747c87;

    @media (max-width: @screen-md) {
      position: static;
    }
  }

  .ant-table-summary {
    z-index: 2;
    table {
      table-layout: auto !important;
    }
    td {
      border-bottom: none !important;
      background: #2c394d !important;
      label {
        color: #fff;
      }
    }
  }
  .submit {
    // position: absolute;
    // left: 0;
  }
}

.table-d {
  tr {
    background-color: #fff;
  }

  .ant-table-tbody {
    tr.ant-table-row-level-0 {
      // td, th{
      //     border-bottom: 5px solid @layout-body-background !important;
      // }
    }

    tr.ant-table-row-level-1 {
      background-color: rgba(0, 252, 63, 0.062);
    }

    tr.ant-table-row-level-2 {
      background-color: rgba(255, 255, 255, 0.062);
    }

    tr.ant-table-row-level-3 {
      background-color: rgba(255, 238, 0, 0.048);
    }
  }
}

.ant-table {
  // border: 1px solid @grey-5;
  .ant-table-column-sorters {
    justify-content: normal !important;
    gap: 4px;
    .ant-table-column-title {
      flex: none !important;
    }
  }
}

.ant-table-container {
  @media (max-width: @screen-md) {
    overflow: scroll;
  }
}

input[type="search"] {
  border-radius: unset;
}

.ant-table-thead>tr>th{
  background-color: #fafafa !important ;

}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;