.slider-config {
  &__main {
    // height: 100%;
  }
  .box-preview__widget {
    gap: 10px;
    position: relative;
    overflow: hidden;

    .slider {
      display: flex;
      flex-direction: row;
      .base {
        top: 45%;
        position: absolute;
        background-color: #b3c2d0;
        border-radius: 50%;
        text-align: center;
        width: 20px;
        height: 20px;
        color: #fff;
      }
      &::after {
        content: "<";
        right: 0px;
        .base;
      }
      &::before {
        content: ">";
        left: 0px;
        .base;
      }
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;