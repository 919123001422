@font-face {
  font-family: "adtech-icon";
  src: url("./../../../adtech.eot");
  src: url("./../../../adtech.eot") format("embedded-opentype"),
    url("./../../../adtech.ttf") format("truetype"),
    url("./../../../adtech.woff") format("woff"),
    url("./../../../adtech.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.adt {
  font-family: "adtech-icon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* You can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow-left-bold:before {
  content: "\e96b";
}
.icon-arrow-left-broken:before {
  content: "\e96c";
}
.icon-arrow-left-bulk .path1:before {
  content: "\e96d";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-arrow-left-bulk .path2:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-arrow-left-linear:before {
  content: "\e96f";
}
.icon-arrow-left-outline:before {
  content: "\e970";
}
.icon-arrow-left-twotone:before {
  content: "\e971";
}

.icon-calendar-bold:before {
  content: "\e950";
}
.icon-calendar-broken:before {
  content: "\e951";
}
.icon-calendar-bulk .path1:before {
  content: "\e952";
  color: rgb(41, 45, 50);
}
.icon-calendar-bulk .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-calendar-bulk .path3:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-calendar-bulk .path4:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-calendar-bulk .path5:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-calendar-bulk .path6:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-calendar-bulk .path7:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-calendar-bulk .path8:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-calendar-linear:before {
  content: "\e95a";
}
.icon-calendar-outline:before {
  content: "\e95b";
}
.icon-calendar-wotone:before {
  content: "\e95c";
}
.icon-grid-bold:before {
  content: "\e95d";
}
.icon-grid-broken:before {
  content: "\e95e";
}
.icon-grid-bulk .path1:before {
  content: "\e95f";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-grid-bulk .path2:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-grid-linear:before {
  content: "\e961";
}
.icon-grid-outline:before {
  content: "\e962";
}
.icon-grid-twotone:before {
  content: "\e963";
}
.icon-search-normal-bold:before {
  content: "\e964";
}
.icon-search-normal-broken:before {
  content: "\e965";
}
.icon-search-normal-bulk .path1:before {
  content: "\e966";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-search-normal-bulk .path2:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-search-normal-linear:before {
  content: "\e968";
}
.icon-search-normal-outline:before {
  content: "\e969";
}
.icon-search-normal-twotone:before {
  content: "\e96a";
}

.icon-arrow-doable-up:before {
  content: "\e94f";
}

.icon-arrow-down-bold:before {
  content: "\e900";
}
.icon-arrow-down-broken:before {
  content: "\e901";
}
.icon-arrow-down-bulk .path1:before {
  content: "\e902";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-arrow-down-bulk .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-arrow-down-linear:before {
  content: "\e904";
}
.icon-arrow-down-outline:before {
  content: "\e905";
}
.icon-arrow-down-twotone:before {
  content: "\e906";
}
.icon-arrow-right-bold:before {
  content: "\e907";
}
.icon-arrow-right-broken:before {
  content: "\e908";
}
.icon-arrow-right-bulk .path1:before {
  content: "\e909";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-arrow-right-bulk .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-arrow-right-linear:before {
  content: "\e90b";
}
.icon-arrow-right-outline:before {
  content: "\e90c";
}
.icon-arrow-right-twotone:before {
  content: "\e90d";
}
.icon-clipboard-text-bold:before {
  content: "\e90e";
}
.icon-clipboard-text-broken:before {
  content: "\e90f";
}
.icon-clipboard-text-bulk .path1:before {
  content: "\e910";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-clipboard-text-bulk .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-clipboard-text-linear:before {
  content: "\e912";
}
.icon-clipboard-text-outline:before {
  content: "\e913";
}
.icon-clipboard-text-twotone:before {
  content: "\e914";
}
.icon-copy-bold:before {
  content: "\e915";
}
.icon-copy-broken:before {
  content: "\e916";
}
.icon-copy-bulk .path1:before {
  content: "\e917";
  color: rgb(41, 45, 50);
}
.icon-copy-bulk .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-copy-linear:before {
  content: "\e919";
}
.icon-copy-outline:before {
  content: "\e91a";
}
.icon-copy-twotone:before {
  content: "\e91b";
}
.icon-empty-wallet-bold:before {
  content: "\e91c";
}
.icon-empty-wallet-broken:before {
  content: "\e91d";
}
.icon-empty-wallet-bulk .path1:before {
  content: "\e91e";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-empty-wallet-bulk .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-empty-wallet-linear:before {
  content: "\e920";
}
.icon-empty-wallet-outline:before {
  content: "\e921";
}
.icon-empty-wallet-twotone:before {
  content: "\e922";
}
.icon-gallery-bold:before {
  content: "\e923";
}
.icon-gallery-broken:before {
  content: "\e924";
}
.icon-gallery-bulk .path1:before {
  content: "\e925";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-gallery-bulk .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-gallery-linear:before {
  content: "\e927";
}
.icon-gallery-outline:before {
  content: "\e928";
}
.icon-gallery-twotone:before {
  content: "\e929";
}
.icon-graph-bold:before {
  content: "\e92a";
}
.icon-graph-broken:before {
  content: "\e92b";
}
.icon-graph-bulk .path1:before {
  content: "\e92c";
  color: rgb(41, 45, 50);
}
.icon-graph-bulk .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-graph-linear:before {
  content: "\e92e";
}
.icon-graph-outline:before {
  content: "\e92f";
}
.icon-graph-twotone:before {
  content: "\e930";
}
.icon-linear:before {
  content: "\e931";
}
.icon-mobile-bold:before {
  content: "\e932";
}
.icon-mobile-broken:before {
  content: "\e933";
}
.icon-mobile-bulk .path1:before {
  content: "\e934";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-mobile-bulk .path2:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-mobile-linear:before {
  content: "\e936";
}
.icon-mobile-outline:before {
  content: "\e937";
}
.icon-mobile-twotone:before {
  content: "\e938";
}
.icon-monitor-bold:before {
  content: "\e939";
}
.icon-monitor-broken:before {
  content: "\e93a";
}
.icon-monitor-bulk .path1:before {
  content: "\e93b";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-monitor-bulk .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-monitor-linear:before {
  content: "\e93d";
}
.icon-monitor-outline:before {
  content: "\e93e";
}
.icon-monitor-twotone:before {
  content: "\e93f";
}
.icon-profile-bold:before {
  content: "\e940";
}
.icon-profile-broken:before {
  content: "\e941";
}
.icon-profile-bulk .path1:before {
  content: "\e942";
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-profile-bulk .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-profile-outline:before {
  content: "\e944";
}
.icon-profile-twotone:before {
  content: "\e945";
}
.icon-receipt-minus-bold:before {
  content: "\e946";
}
.icon-receipt-minus-broken:before {
  content: "\e947";
}
.icon-receipt-minus-bulk .path1:before {
  content: "\e948";
  color: rgb(41, 45, 50);
}
.icon-receipt-minus-bulk .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}
.icon-receipt-minus-bulk .path3:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(41, 45, 50);
}
.icon-receipt-minus-linear:before {
  content: "\e94b";
}
.icon-receipt-minus-outline:before {
  content: "\e94c";
}
.icon-receipt-minus-twotone:before {
  content: "\e94d";
}
.icon-tablet-outline:before {
  content: "\e94e";
  color: #636e7b;
}
