@import "./../../app/theme.less";
@import "./../../app/mixins.less";

.page__title {
  margin: 0px !important;
  padding: 22px 24px;
  min-width: 140px;
  background-color: #fff;
  font-variation-settings: "wght" 500, "dots" 1 !important;
  &__row {
    .mixin-max-content();
    @media (min-width: @screen-xxl) {
      padding: 0 20px !important;
    }
    padding: 0px !important;
    justify-content: space-between;
  }

  &__wrapper {
    background-color: #fff;
  }

  h4 {
    margin: 0 !important;
  }

  &__main {
    display: flex !important;
    align-items: center;
  }
  .title__helper {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 15px;
  }

  .helper__button {
    background-color: #fff;
    padding: 0px 5px;
    box-shadow: 0 0 9px 0 rgba(63, 77, 105, 0.04);
    height: 48px;
    width: 48px;
    border: 0;

    img {
      width: 90%;
    }

    @keyframes blink {
      0%,
      100% {
        opacity: 0;
      }

      /* more concise! */
      50% {
        opacity: 1;
      }
    }

    .image_on {
      display: none;
    }

    .image_off {
      display: block;
    }

    &:hover {
      .image_on {
        display: block;
      }

      .image_off {
        display: none;
      }
    }

    dot {
      width: 5px;
      height: 5px;
      background-color: @secondary-color;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 5px;
      right: 5px;
      animation: blink 2s infinite;
    }
  }
}
.page_subtitle{
  color: @text-color;
  font-variation-settings: normal;
  margin-top: 1em;
}
.plus {
  text-align: left;
  .page__plus {
    font-size: 2.2em;
    color: @primary-color !important;
    margin-left: 8px;
    transition: all 0.5s !important;
  }
  .page__plus:hover {
    color: rgb(36 , 98 , 202) !important;
  }
}

//ltrStyles
.ltr_cls {
  .plus {
    text-align: right;
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;