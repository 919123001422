@import './../../app/theme.less';

.card-skeleton {
  background: @primary-color;
  padding: 10px;
  width: 100px;
  border-radius: 10px;
  margin: 8px;
  min-width: 90px;
  &.active {
    background-color: red !important; 
  }
  &.horizontal{
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    .img {
      margin-top: 10px;
      width: 50px;
    }
    .content {
      margin-top: 10px;
      flex-grow: 1;
    }
  }

  &.banner {
    width: 100%;
    margin: 0px;
    &.card-skeleton {
      height: 100% !important;
      width: 100% !important;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .img {
        display: flex;
        justify-content: center;
        svg {
          flex-grow: 1;
        }
      }
    }
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;