@import "./../../../../app/theme.less";

.category-filter {
  .filter__category {
    .ant-tree-list {
      .ant-tree-list-holder {
        margin-top: 8px;
        height: 300px;
        overflow-y: auto;
      }
      .ant-tree-treenode {
        padding: 8px 0;
      }
    }
  }
  .filter__badge {
    .ant-badge-status-default {
      // color: @dark-gray;
      background-color: @dark-gray;
    }
    &-icon {
      color: @dark-gray;
      font-size: 20px;
    }
    &-retry {
      font-size: 18px;
      margin: 0px 5px;
    }
  }
}

.basket-preview {
  .avatar {
    width: 70px;
    height: 70px;
  }
  .delete-icon {
    font-size: 18px;
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
}

.banner_d {
  &__thumbnail {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #f6f6f6;
    padding: 10px;
    border-radius: 5px;

    img {
      // border-radius: 10px;
      background-color: #eee;
      max-width: 100%;
      // height: 240px;
    }
    .img__skelton {
      // height: 240px;
      // background-color:red;
    //   width: 100% !important;
    //   height: 200px !important;
    //   display: block;
    }
    margin-bottom: 10px;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    .flag_default {
      background-color: #fff;
      height: 40px;
      width: 150px;
      position: absolute;
      transform: rotate(-45deg);
      left: -37px;
      top: 19px;
      text-align: center;
      color: #ff8603;
      font-size: 1.1em;
      padding-top: 7px;
    }
  }
  &__detail {
    background-color: #f5f7fa;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #e7ecf2;

    table {
      width: 100%;
      tr td {
        padding: 5px;
        &:first-child {
          color: @gray-new;
          width: 150px;
        }
      }
    }
  }
  &__footer {
    font-size: 12px;
    padding-top: 10px;
    .icon {
      padding: 5px;
      font-size: 14px;
      color: @info-color;
    }
  }
}

.ant-slider-track {
  background-color: @primary-color !important;
}
.ant-slider-handle {
  border-color: @primary-color !important ;
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;