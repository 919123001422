@import './../../app/theme.less';

.ant-message {
  // top: unset !important;
  // bottom: 20px !important;
}

.d-flex {
  display: flex;
  flex-direction: row;
}

.auth {
  &-wrap {
    width: 100%;
    position: relative;

    .ant-form-item-explain {
      text-align: right;
      direction: rtl;
    }

    input[type='password'] {
      // text-align: center;
    }
  }

  &__card {
    // max-width: 682px;
    width: 682px;
    &__register {
      width: 768px;
    }
    margin: auto !important;
    overflow: unset !important;

    .d-flex {
      flex-direction: row-reverse;
    }

    .ant-card-body {
      padding: 0px;

    }

    // min-height: 40vh;
    @media (max-width: @screen-md) {
      width: auto;
      min-width: @screen-xs - 140;

      .d-flex {
        flex-direction: column;
      }
    }
  }

  &__lang {
    position: absolute;
    bottom: -50px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }

  &__image {
    // width: 45%;
    width: 300px;
    flex-shrink: 0;
    background: @auth-bg-1;
    background-image: linear-gradient(to bottom right, @auth-bg-2 15%, @auth-bg-3 100%);
    position: relative;
    overflow: hidden;
    border-radius: 11px 0 0 11px;

    @media (max-width: @screen-md) {
      max-width: unset;
      width: unset;
    }

    // background-image: url('../../assets/images/neature.jpg');
    .image__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      /* opacity: 0.5; */
      /* bottom: 10px; */
      display: flex;
      justify-content: center;

      img {
        display: block;
        position: absolute;
        bottom: 5%;
        right: auto;
        left: auto;
        width: 90%;
        opacity: .9;

        @media (max-width: @screen-md) {
          height: 100px;
          width: 100px;
          left: 20px;
        }
      }
    }

    .image__info {
      h3 {
        color: @secondary-color;
        font-weight: bold;
      }

      p {
        margin-top: 10px;
      }

      position: relative;
      z-index: 2;
      padding: 24px;
      color: #fff;
      font-weight: bold;
      height: 100%;

      .image__footer {
        position: absolute;
        bottom: 20px;
        left: 20px;

        img {
          transition: 0.70s;
          -webkit-transition: 0.70s;
          -moz-transition: 0.70s;
          -ms-transition: 0.70s;
          -o-transition: 0.70s;

          &:hover {
            transition: 0.70s;
            -webkit-transition: 0.70s;
            -moz-transition: 0.70s;
            -ms-transition: 0.70s;
            -o-transition: 0.70s;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__form {
    flex-grow: 1;
    // background-color: blue;
    padding: 36px !important;
    min-height: 350px;

    @media (max-width: @screen-md) {
      padding: 16px !important;
    }

    &--login {
      padding-top: 100px !important;
      padding-bottom: 100px !important;
    }

    &--forgot {
      padding-top: 161px !important;
      padding-bottom: 161px !important;
    }

    input {
      font-weight: 500;
    }

    .direction {
      text-align: right;
      direction: ltr;
    }
  }

  &__actions {
    font-weight: bold;

    .space-or {
      margin-right: 15px;
      margin-left: -4px;
    }

    button {
      font-weight: bold;
    }
  }

  &__password {
    .ant-input-password input {
      direction: ltr;
      text-align: right;
    }

    .ant-form-item-explain {
      &>div {
        // font-size: 0.8em;
      }
    }
  }

  &__title {
    margin-bottom: 25px !important;
  }

  &__description {
    margin-bottom: 25px !important;
    color: @dark-gray;

    &.margin-0 {
      margin-bottom: 0px !important;
    }
  }

  &__username {
    direction: rtl;
  }

  &__modal {
    direction: rtl;
    text-align: justify;

    &>div {
      white-space: pre-line;
    }
  }

  &__phone {
    direction: ltr;
    margin: 0 5px;
    // text-align: left;
    unicode-bidi: embed;
  }

  &__forgot {
    text-align: left;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    direction: rtl !important;
    text-align: right !important;
  }
}

.icon__roleType {
  color: rgba(0, 0, 0, .45);
  padding: 12px;
  font-size: 16px;
  position: absolute;
  left: 0px;
}

.codeInput {
  text-align: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}


.test-alert {
  position: fixed;
}

//ltStyle

.ltr_cls {
  .icon__roleType {
    left: 82%;
    right: 0;
  }

  .direction {
    text-align: left;
    direction: ltr;
  }

  .auth__image {
    border-radius: 0 11px 11px 0;
  }

  .ant-input-password input{
    text-align: left !important;
  }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;