@import "./../../../app/theme.less";

.widget-wrapper {
    &__extra {
      display: flex;
      position: relative;
    }
    &__filter {
      position: absolute;
      left: -24px;
      top: 45px;
      z-index: 10;
      .backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        // background-color: red;
        z-index: -1;
        right: 0px;
        top: 0px;
      }
      .ant-card-head {
        min-height: auto;
      }
      .ant-card-head-title {
        padding: 10px 0;
        font-size: 0.8em;
      }
      .filter__body {
        padding: 16px;
      }
      .filter__footer {
        border-top: 1px solid #eff1f5;
        background-color: #eff1f5;
        padding: 10px;
        // border-top: 1px solid #B3BBC5;
        border: 1px solid #eee;
        .close-icon {
          color: @gray-new;
        }
  
        button {
          color: @dash-color-5;
          display: block;
          span {
            min-height: 40px;
          }
        }
      }
      .ant-card-body {
        padding: 0px;
      }
    }
  }
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;