@import "../app/theme-ssp.less"; // variables to override above

@input-radius-small: 8px;
@input-radius-base: 10px;
@input-radius-large: 12px;
@base-height: 40px;
@space-base: 8px;
@modal-border-radius: 14px;

.ant-radio-button-wrapper-checked {
  background: #646b7a !important;
}

// alerts
.ant-alert {
  border: unset;
  border-radius: @border-radius-base * 2.5;
  .ant-alert-icon {
    font-size: 16px;
  }
  .anticon-close {
    font-size: 22px;
  }
  &.ant-alert-info {
    .icon-info {
      font-size: calc(1rem + 6px);
      margin-bottom: 4px;
      color: @info-color;
    }
    .ant-btn-primary {
      background-color: @info-color;
      border-color: @info-color;
    }
  }
  &.ant-alert-success {
    .ant-alert-message {
      color: @success-color-text !important;
    }
  }
  &.ant-alert-warning {
    .ant-alert-message {
      color: @warning-color-text !important;
    }
  }
  &.ant-alert-error {
    .ant-alert-message {
      color: @error-color-text !important;
    }
  }
  &.ant-alert-info {
    .ant-alert-message {
      color: @info-color-text !important;
    }
  }
}

// inputs
// .ant-input-affix-wrapper {
//   padding: 8px 11px !important;
// }

.ant-checkbox-indeterminate {
  .ant-checkbox-inner::after {
    width: 8px !important;
    height: 2px !important;
  }
}

.ant-picker {
  border-radius: @input-radius-base;
}

.ant-input {
  border-radius: @input-radius-base;
}
.ant-input-affix-wrapper {
  border-radius: @input-radius-base;
}
.ant-select-selector {
  border-radius: @input-radius-base !important;
}

.ant-form-item-explain-error {
  margin-top: 2px;
  font-size: 11px;
}
// button 
.ant-btn {
  &-sm {
    font-size: @font-size-base - 2px;
  }
}