.toolbar-chart {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #e4e7eb;
  .ant-tabs-nav-list {
    flex: 1;
  }
  .ant-tabs-tab {
    flex: 1;
    padding: 0px;
    display: flex;
    justify-content: center;
    &.ant-tabs-tab-active {
      background-color: #f2eefb;
    }
    &:nth-child(4) {
      border-left: 1px dashed #BCC4CC;
    }
  }
  .tab-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px;
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;