.select-user-type {
    // width: 300px;
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
    flex-direction: column;
    .item-option {
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid #dddddd;
        box-shadow: 0px 0px 5px #dddddd;
        b {
            font-size: 1.1em;
            font-weight: bolder;
            padding-bottom: 2px;
            display: block;
        }
        &__intro {
            font-size: 13px;
            color: #565656;
            // color: rgb(79, 79, 79);
        }
        &:hover {
            border: 1px solid #b9b9b9;

            cursor: pointer;
        }
        &.active{
            border: 1px solid #468bff;
            // color: #fff;
            .item-option__intro {
                // color: #fff;
            }
        }
    }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;