@import "./../../app/theme.less";

.PlaceStepper {
  background: #fff;
  border-top: 2px solid #eee;
  margin-bottom: 8px;
  padding: 0 32px;

  .ant-steps-navigation {
    display: inline-flex;
    gap: 40px;
  }
  .ant-steps-item {
    margin: 0 2px;
    min-width: 150px;
    flex: none !important;
    &::after {
      margin-right: 12px !important;
    }
    .ant-steps-item-icon {
      width: 22px;
      height: 22px;
      vertical-align: middle;
      line-height: 1.8;
      background-color: #f2eefb;
      border: unset;
      font-size: 14px;
      font-weight: 600;
      margin: 5px 8px 0 0;
    }
  }

  .stepper {
    margin-bottom: 8px;
  }
  .step__active {
    // background-color: #fff;
    border-bottom: 2px solid #6a4c93;
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;