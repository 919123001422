@font-face {
	font-family: GoogleSans;
	font-style: normal;
	font-weight: normal;
	src: url('./../../gsans/GoogleSans-Regular.eot');
	src: //url('./../../gsans/eot/GoogleSans-Regular.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./../../gsans/GoogleSans-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./../../gsans/GoogleSans-Regular.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./../../gsans/GoogleSans-Regular.ttf') format('truetype');
	// unicode-range: U+00-FF;
	unicode-range: U+0000-007F; /* Unicode range for English characters */
}
