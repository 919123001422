@import "@/app/ant-custom.less";

@color-view: #ff7b95;
@color-imp: #df5fff;
@color-click: #468bff;
@color-ctr: #ff7438;
@color-fill: #FFA800;
@color-win: #21B2C6;

.tile-count {
  .tile-count__title {
    p {
      margin: 15px 0;
      margin-bottom: 5px;
    }
  }
  &--view {
    .tile-count__title {
      color: @color-view;
    }
  }
  &--imp {
    .tile-count__title {
      color: @color-imp;
    }
  }
  &--click {
    .tile-count__title {
      color: @color-click;
    }
  }
  &--ctr {
    .tile-count__title {
      color: @color-ctr;
    }
  }
  &--fill_rate {
    .tile-count__title {
      color: @color-fill;
    }
  }
  &--win_rate {
    .tile-count__title {
      color: @color-win;
    }
  }
  &__counter {
    font-size: 2.2em;
    color: @text-title;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &__overview {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      .label-title {
        direction: rtl;
        text-align: right;
        color: @text-text-hint-2;
      }
      span {
        color: @text-color;
      }
    }
  }
  .percent {
    display: inline-block;
    position: absolute;
    top: @space-base * 2;
    left: @space-base * 2;
    border-radius: 30px;
    padding: 2px 5px;
    font-size: @font-size-sm;

    background-color: #eef1f9;
    color: #7d8793;

    i {
      text-align: center;
      display: block;
      font-style: normal;
      min-width: 15px;
      min-height: 15px;
    }
  }
  .chart {
    position: absolute;
    left: @space-base * 2;
    bottom: @space-base * 2;
  }
}

@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;