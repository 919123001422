.profile {
    &__avatar{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .change-phone-number{
            padding: 10px;
            .ant-input-group > .ant-input-rtl{
                border-radius: 0;
                direction: ltr;
            }
        }
        .ant-avatar{
            width: 150px;
            height: 150px;
        }
        .af_avatar {
            display: flex;
            position: relative;
            justify-content: center;
            margin-bottom: 24px;
            &:hover{
                cursor: pointer;
                .af_avatar__icon{
                    display: block;
                }
            }
            &__icon{
                display: none;
                width: 100%;
                position: absolute;
                height: 100%;
                font-size: 3em;
                background-color: #eee;
                border-radius: 50%;
                opacity: .5;
                text-align: center;
                &--ico{
                    margin-top: 40%;                    
                }
            }
        }
    }
    &__info {
        &--ul{
            list-style-type: none;
            padding: 0;
            &>li{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 8px 16px;
                padding-top: 8px;
                align-items: center;
                &:first-child {
                    border-bottom: 1px solid #cfd5dd;
                    padding-bottom: 8px;
                }
            }
        }
    }
    &__phone {
        direction: ltr;
    }
    .steps-content {
        margin-top: 40px;
    }
    .content_info {
        display: none;
    }
    .content_info.active {
        display: block;
    }
    .afl-form .ant-form-item {
        display: flex !important;
    }
    .sheba__wrap {
        .ant-form-item-children-icon {
            right: 0 !important;
        }
    }
    .ant-card{
        margin-top: 0 !important;
    }
    .header {
        display: flex;
        justify-content: space-between;
        margin-left: 2px;
        align-items: baseline;
    }
}
.profile_form-kyc {
    .checkbox-group {
        display: flex;
        flex-direction: column;
        &>label{
            border-bottom: 1px solid #eee;
            padding: 8px 0;
        }
    }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;